import React from "react"
import { graphql, Link } from "gatsby"
import { Box, Grid, Themed } from "theme-ui"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { useMediaQuery } from 'react-responsive'
import Seo from "../components/seo"

const Activist = ({ data }) => {

  const activist = data.activistsJson
  const dataFragments = data.dataJson
  const isDesktopOrLaptop = useMediaQuery({minWidth: 1224})
  const isTabletOrMobile = useMediaQuery({maxWidth: 1224})
  const color = activist.country === `Honduras` ? `honduras` : `guatemala`
  const imageHorizontal = activist.featuredimagehorizontal === `true` ? `contain` : `cover`
  const numberColumnsGallery = activist.gallerycolumns ? activist.gallerycolumns : `null`

  return (
    <Layout>
      <Seo
        title={activist.name}
        description={activist.quote}
        image={activist.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src}
      />
      <Grid
        as={`main`}
        sx={{
          gridTemplateColumns: [  `1fr`,
                                  `1fr`,
                                  `1fr`,
                                  `30vw 1fr`,
                                  `30vw 1fr`,
                                  `27vw 1fr`
                                ],
          gridTemplateRows: [ `auto`,
                              `auto`,
                              `auto`,
                              `4rem 72vh auto auto`,
                              `4rem 75vh auto auto`,
                              `6rem 77.25vh auto auto`,
                            ],
          gridTemplateAreas:[ `"entryheader" "entryquote" "entrytext" "entryfeaturedimage" "entryvideo" "entrygallery"`, 
                              `"entryheader" "entryquote" "entrytext" "entryfeaturedimage" "entryvideo""entrygallery"`,  
                              `"entryheader" "entryquote" "entrytext" "entryfeaturedimage" "entryvideo" "entrygallery"`, 
                              `"entryheader entryquote" "entrytext entryfeaturedimage" "entryvideo entryvideo" "entrygallery entrygallery"`, 
                              `"entryheader entryquote" "entrytext entryfeaturedimage" "entryvideo entryvideo" "entrygallery entrygallery"`, 
                              `"entryheader entryquote" "entrytext entryfeaturedimage" "entryvideo entryvideo" "entrygallery entrygallery"`, 
                            ],
          gridColumnGap: 4,
          gridRowGap: 3,
          alignItems: `flex-end`,
          h1: {
            color: `${color}`,
            fontSize: [3, 3, 3, 4, 4, 5],
          },
          ".meta-info": {
            mt: 0,
            mb: 3,
            li: {
              fontSize: 1,
            }
          },
          ".country-name, .link-to-video": {
            fontSize: 0,
            display: `inline-block`,
          },
          ".country-name": {
            mb: 3,
          },
          ".link-to-video": {
            ml: 3,
            mb: 0,
          },
          ".entry-header": {
            gridArea: `entryheader`,
          },
          ".entry-text": {
            gridArea: `entrytext`,
          },
          ".entry-featured-image": {
            gridArea: `entryfeaturedimage`,
          },
          ".entry-quote": {
            gridArea: `entryquote`,
            mb: [2, 2, 2, 0, 0, 0],
          },
          ".entry-video": {
            gridArea: `entryvideo`,
            maxWidth: [`auto`, `auto`, `auto`, `auto`, `auto`, `90%`],
            pt: [0, 0, 0, 4, 4, 4],
          },
          ".entry-gallery": {
            gridArea: `entrygallery`,
            gridTemplateColumns: `repeat(${numberColumnsGallery}, 1fr [col])`,
            gridTemplateRows: `auto`,
          },
          ".video-wrapper": {
            position: `relative`,
            paddingBottom: `56.25%`,
            height: `0`,
            iframe: {
              position: `absolute`,
              top: `0`,
              left: `0`,
              width: `100%`,
              height: `100%`,
            }
          },
          ".quote": {
            borderLeftColor: `${color}`,
            color: `${color}`,
            display: `inline-block`,
            fontStyle: `italic`,
            fontSize: 1,
            m: 0,
          },
          ".entry-text p": {
            mb: 3,
          },
          ".entry-text p:last-of-type": {
            mb: 0,
          }
        }}
      >
          <Box
            as={`header`}
            className="entry-header"
          >
            {isTabletOrMobile &&
              <Themed.a as={Link} className="country-name" to={`/${dataFragments.fragment_3}${color}`}>{activist.country}</Themed.a>
            }
            <Themed.h1>{activist.name}</Themed.h1>
          </Box>
          <Box className="entry-text">
            {isDesktopOrLaptop &&
              <Themed.a as={Link} className="country-name" to={`/${dataFragments.fragment_3}${color}`}>{activist.country}</Themed.a>
            }
            <Themed.ul className="meta-info">
              <Themed.li><span>{dataFragments.fragment_1}</span>: {activist.lawsuit.name} - {activist.lawsuit.specific}</Themed.li>
              <Themed.li><span>{dataFragments.fragment_2}</span>: {activist.municipality} ({activist.department})</Themed.li>
            </Themed.ul>
            <Box
              sx={{
                variant: 'styles',
              }}
              dangerouslySetInnerHTML={{ __html: activist.content}}
            />
          </Box>
          <Box className="entry-featured-image">
            <GatsbyImage
              image={activist.featuredimage.childImageSharp.gatsbyImageData}
              alt={`${dataFragments.fragment_5} ${activist.name}`}
              objectFit={`${imageHorizontal}`}
              style={{
                maxHeight: `72vh`
              }}
              objectPosition="0 50%"
            />
          </Box>
          {activist.gallery &&
            <Grid className="entry-gallery">
              {activist.gallery.map(( single, i ) => (
                <GatsbyImage
                  image={single.image.childImageSharp.gatsbyImageData}
                  alt={single.alt}
                  key={i}
                />
              ))}
            </Grid>
          }
          <Box className="entry-quote">
            <Themed.blockquote className="quote">{activist.quote}</Themed.blockquote>
            {activist.video &&
              <Themed.p className="link-to-video">
                <Themed.a href="#video">{dataFragments.fragment_4}</Themed.a>
              </Themed.p>
            }
          </Box>
          {activist.video &&
            <Box id="video" className="entry-video">
              <div className="video-wrapper">
                <iframe src={`https://player.vimeo.com/video/${activist.video}?title=0&byline=0&portrait=0`} title={`${activist.video}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
              </div>
            </Box>
          }
      </Grid>
    </Layout>
  )

}

export default Activist

export const query = graphql`
  query($slug: String!) {
    activistsJson(slug: {eq: $slug}) {
      name
      content
      quote
      country
      department
      municipality
      video
      slug
      lawsuit {
        name
        specific
      }
      featuredimage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      featuredimagehorizontal
      gallerycolumns
      gallery {
        alt
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    dataJson(slug: {eq: "activist"}) {
      fragment_1
      fragment_2
      fragment_3
      fragment_4
      fragment_5
    }
  }
`

